import { Flex, Text, Box, Fade, Button, Center } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export function MainHero({ onCtaClick = () => null, ctaText = 'Get Started' }) {
  const actions = ['add borders', 'switch tabs', 'format text'];
  const [action, setAction] = useState<string>(actions[0]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setInterval(() => {
      setAction((act) => {
        const nextIndex = actions.findIndex((a) => a === act) + 1;
        return actions[nextIndex] ?? actions[0];
      });
    }, 3000);
  }, []);

  return (
    <Center w="100%" key="main-hero-center" id="main-hero-content">
      <Flex
        m="auto"
        width="100%"
        maxWidth={{ base: '600px', md: '1200px' }}
        direction={'column'}
        align="center"
        px={[8, 4]}
      >
        <Text
          fontWeight={{ base: '800', md: '700' }}
          fontSize={{ base: '2.3rem', md: '3.75rem', lg: '5.75rem' }}
          textAlign="center"
          color="gray.700"
        >
          Save Your Time.
        </Text>
        <Text
          fontWeight={{base: '800', md: '700'}}
          fontSize={{ base: '2.3rem', md: '3.75rem', lg: '5.75rem' }}
          lineHeight={{ base: '2rem', md: '5rem' }}
          textAlign="center"
          bgGradient="linear(to-l, blue.700, yellow.700, red.600)"
          bgClip="text"
        >
          Learn Shortcuts.
        </Text>
        <Box
          color="gray.600"
          py={2}
          maxWidth={['380px', '800px']}
          mt={[2, 4]}
          fontWeight={{base: 500, md: 400}}
          textAlign="center"
          fontSize={{base: 'md', md: '3xl'}}
        >
          <Flex>
            <Text>Every time you use a mouse to</Text>
            <Box w={{base: 100, md: 180}} ml={{base: 1, md: 2}} mr={0} position="relative">
              {actions.map((a) => (
                <Box
                  key={a}
                  position={'absolute'}
                  top="0"
                  left={'0'}
                  ml={0}
                  textAlign={['left', 'left']}
                >
                  <Fade in={a === action} unmountOnExit>
                    <Text
                      as="span"
                      fontWeight={'600'}
                      bgGradient="linear(to-l, yellow.700, red.600, blue.700)"
                      bgClip="text"
                    >
                      {a},
                    </Text>
                  </Fade>
                </Box>
              ))}
            </Box>
          </Flex>
          <Text>you lose a second. Let's get it back.</Text>
        </Box>
        <Flex justify={'center'} align="center" mt={12}>
          <Button
            size={'lg'}
            colorScheme="brand"
            onClick={() => {
              onCtaClick();
              setLoading(true);
            }}
            isLoading={loading}
          >
            {ctaText}
          </Button>
        </Flex>
      </Flex>
    </Center>
  );
}

export default MainHero;
