import { Center, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import router from 'next/router';
import AppContent from 'modules/shared/templates/AppContent';
import { useUser } from 'modules/auth/use-user';
import MainHero from './MainHero';
import { setLocalUserState } from 'utils/cookie';
import { getPlatform } from 'utils/device';

export function Home() {
  const platform = getPlatform();
  const routes = {
    apps: '/apps',
    cheatsheet: `/${platform}/shortcuts/google-sheets/top-shortcuts/d466ba3f-763d-4cef-8a9b-46d9e27bb246`
  };
  const nextRoute: string = useBreakpointValue(
    { base: 'apps', md: 'cheatsheet' },
    'md'
  );

  const handleCtaClick = () => {
    setLocalUserState({ skipHome: true });
    const url = routes[nextRoute];
    router.push(url, url);
  };

  return (
    <AppContent header={<div />} width="full">
      <Center width="100%" key="main-hero" mt={{ base: 16 }}>
        <MainHero onCtaClick={handleCtaClick} />
      </Center>
    </AppContent>
  );
}

export default Home;
